import 'src/polyfills'
import 'styles/global.css'

import memoize from 'lodash/memoize'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { UserProvider } from 'src/contexts/UserContext'

import { USE_FIREBASE_EMULATOR } from '../constants'

// ignore in-browser next/js recoil warnings until its fixed.
const mutedConsole = memoize(console => ({
  ...console,
  warn: (...args: (string | string[])[]) =>
    args[0].includes('Duplicate atom key') ? null : console.warn(...args),
}))
global.console = mutedConsole(global.console)

function MyApp({ Component, pageProps }: AppProps) {
  const props: {
    [key: string]: any
    disableNProgress?: boolean
    disableIntercom?: boolean
  } = {
    ...pageProps,
  }
  useEffect(() => {
    if (props.disableNProgress) return
    Router.events.on('routeChangeStart', () => NProgress.start())
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())
  }, [props.disableNProgress])

  return (
    <RecoilRoot>
      <UserProvider>
        <Head>
          <meta charSet='utf-8' />
          <meta
            name='viewport'
            content='initial-scale=1.0, width=device-width'
          />
          <link
            rel='shortcut icon'
            type='image/png'
            href='https://cdn.arcade.software/images/button-logo-128.png'
          />
          <link
            rel='apple-touch-icon'
            href='https://cdn.arcade.software/images/button-logo-128.png'
          />
          <meta
            name='msapplication-TileImage'
            content='https://cdn.arcade.software/images/button-logo-128.png'
          ></meta>
        </Head>
        {!props.disableIntercom && !USE_FIREBASE_EMULATOR && (
          <Script
            id='intercom-script'
            dangerouslySetInnerHTML={{
              __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/qg0pl3tr';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
            }}
          ></Script>
        )}

        <Component {...pageProps} />
      </UserProvider>
    </RecoilRoot>
  )
}

export default MyApp
